<template>
    <div class="content-wrapper">
            <div class="container mt-5">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <slot name="notification"></slot>
                        <div class="card">
                            <div class="card-header">
                                Gudang
                            </div>
                            <!-- {{ name }} -->
                            <form role="form" @submit="e => { e.preventDefault(); onSubmit(fields); }">
                                <div class="card-body">
                                    
                                    <div class="form-group">
                                        <label for="name">Kode Gudang <span class="text-danger">(*)</span></label>
                                        <input type="text" class="form-control" id="name" v-model="fields.gdg_code" placeholder="Name" v-uppercase >
                                    </div>
                                    <div class="form-group">
                                        <label for="name">Nama Gudang <span class="text-danger">(*)</span></label>
                                        <input type="text" class="form-control" id="name" v-model="fields.gdg_name" placeholder="Name" required v-uppercase>
                                    </div>
                                   
                                    <div class="form-group">
                                        <label for="name">Deskripsi <span class="text-danger">(*)</span></label>
                                        <input type="text" class="form-control" id="name" v-model="fields.gdg_desc" placeholder="Name"                                     
                                        v-uppercase>
                                        
                                    </div>
                                                                
                                    
                                    
                                    <div class="form-group">
                                        <label for="handphone">Status <span class="text-danger">(*)</span></label>
                                        <v-select :options="['ACTIVE', 'IN ACTIVE']" v-model="fields.gdg_status"></v-select>
                                    </div>   

                                    <div class="card-footer">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <a @click="$router.go(-1)" class="btn btn-danger">Back</a>
                                    </div>
                                
                                </div>
                            </form>    
                        </div>
                    </div>
                </div>
            </div>
        
    </div> 
</template>

<script>
    export default {
        props: {
            initialFields: {
                type: Object,
                required: true,
            },
            onSubmit: {
                type: Function,
                required: true,
            },
            update: {
                type: Boolean,
                default: false
            }
        },
        data : function() {
            return {
                fields: null,
            };
        },
        beforeMount() {
            //console.log(...this.initialFields);
            this.fields = { ...this.initialFields };            
            console.log('before mount di form');
            console.log(this.fields);
            },
        methods: {
             tes(a ) {
                console.log(a.target._value);
             }
            }
    }
</script>