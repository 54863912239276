<template> 
   <div>
        <template-form  :initialFields="fields" :onSubmit="onSubmit">
            <template v-slot:notification>
                <message :type="ntf.type" :status="ntf.status" :message="ntf.message" />
            </template>
		</template-form>
	</div>
</template>
 
<script>
    import Form from './Form.vue';
    //import Message from '../../../../../components/Message.vue';
    //import { defaultErrors } from "../../../../../helpers";
    export default {
        props: { 
            update: {
                type: Boolean,
                default: false
            }
        },
/*         data : function() {
            return {
                isLoading: false,
                fields: {
                    name: '',
                    address: '',
                    phone: ''
                },
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            };
        }, */
        data() {
            return {
                isLoading: false,
                fields: {
                    name: '',
                    address: '',
                    phone: ''
                },
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            }
        },
        components: {
            'template-form': Form//, Message
            },
        methods: {
                async on1Submit(fields) {
                    console.log(fields);
                    this.isLoading = true;
                    
                    try {
                        //const response = await axios.post('/api/Gudang', fields);
                        //console.log(response);
                        //this.fields = defaultFields();
                        
                        this.ntf = {
                            type: 1,
                            message: 'Success create Gudang',
                            status: true
                        };
                    } catch (error) {
                        this.ntf = {
                            type: 3,
                            //message: defaultErrors(error.response.data.errors),
                            status: true
                        };
                    }
                    this.isLoading = false;
                },
                async onSubmit(fields) {
                    this.isLoading = true;
                    console.log(fields);                    
                    
                    try {
                        const response = await this.apiPosts(`gudangs/store`, fields);    
                        //console.log(response);                        
                        if(response.status == 200) {                    
                            this.$router.push({
                            name: 'gudang'
                            });   
                        }
                        else {
                            console.log('dalam error');                        
                            console.log(response.data.error);     
                            let errors = response.data.error;
                            let msg='';
                            for (let field of Object.keys(errors)) {
                                msg =msg+'<br/> '+errors[field][0];
                                //this.flash(errors[field][0], 'error');
                            }  
                            //this.$swal("Error", msg);
                            this.$swal({
                                title: 'Error',
                                html: msg,
                                border: '1px solid #F0E1A1',
                                backdrop: "rgba(43, 165, 137, 0.45)",
                            
                            });
                            /* let errors = response.data.errors;
                            for (let field of Object.keys(errors)) {
                                this.flash(errors[field][0], 'error');
                            }  */
                        }
                    } catch (error) {
                        console.log('error lo');                                         
                         //this.errors = error.response.data.errors; // this should be errors.
                        console.log(error);                                         
                    }
                    this.isLoading = false;
                },
            }
    }
</script>